import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { screenSizes } from '../../../terhiru-ui/js/consts';
import { isSmallerThanWindowSize } from '../../../terhiru-ui/js/utils';
import './HeaderSection.scss';

function HeaderSection() {
  const [isOneLineHeader, setIsOneLineHeader] = useState(isSmallerThanWindowSize(1200));

  useEffect(() => {

    function windowResize() {
      setIsOneLineHeader(isSmallerThanWindowSize(screenSizes.xl))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  return (
    <>
      <div className='position-relative overflow-clip '>
        <section className='welcome-section'>
            <div className='d-flex flex-column flex-grow-1 p-t-4'>
              <div className='welcome-section-text-container'>
                <h1 className='welcome-section-text'>
                  <span className='fw-normal'>
                    <FormattedMessage id='app.home.welcome.header.1'/>
                  </span> 
                  <FormattedMessage id='app.home.welcome.header.2'/><br/>
                  <FormattedMessage id='app.home.welcome.header.3'/>
                  <span className='fw-normal'>
                    <FormattedMessage id='app.home.welcome.header.4'/>
                  </span> <FormattedMessage id='app.home.welcome.header.5'/> <br/>
                  <span className='fw-normal'> <FormattedMessage id='app.home.welcome.header.6'/></span>
                </h1>
              </div>
            </div>
            <img className='bg-img opacity-25 mix-blend-mode-color-dodge n-z-index-1' src='https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/milky-way-full-stars-space.png' alt='milky way'/>
            <img className='bg-img opacity-75 mix-blend-mode-color-dodge n-z-index-1' src='https://storage.googleapis.com/public-bucket.tehiru.space/images/backgrounds/pexels-philippe.png' alt='pexels effect'/>
            <span className='welcome-section-gradient'></span>
          </section>

          <section className='description-section'>
            <p>
              <span className='h3' style={{color: "var(--tse-secondary)"}}>
                {
                  isOneLineHeader ?
                    <>
                    <FormattedMessage id='app.home.welcome.description.header-l'/><br />
                    </> :
                    <>
                      <FormattedMessage id='app.home.welcome.description.header-l-1'/><br />
                      <FormattedMessage id='app.home.welcome.description.header-l-2'/><br />
                    </>
                }
              </span>
              <span>
                {
                  isOneLineHeader ?
                    <>
                    <FormattedMessage id='app.home.welcome.description.p'/>&nbsp;
                    </> :
                    <>
                      <FormattedMessage id='app.home.welcome.description.p.l.1'/><br/>
                      <FormattedMessage id='app.home.welcome.description.p.l.2'/><br/>
                      <FormattedMessage id='app.home.welcome.description.p.l.3'/><br/>
                      <FormattedMessage id='app.home.welcome.description.p.l.4'/>&nbsp;
                    </>
                }
              </span>
              <span className='fw-bold'><FormattedMessage id='app.home.welcome.description.p.l.5'/></span>
            </p>
            <div className='rocket-image-container'>
              <img src='https://storage.googleapis.com/public-bucket.tehiru.space/images/tehiru-rocket.png' alt='Tehiru rocket'/>
            </div>
          </section>
      </div>
    </>
  );
}

export default HeaderSection;
