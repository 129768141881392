import React, { useEffect, useState } from 'react';
import './PageLoader.scss';
import pageLoaderEventBus from '../../core/pageLoaderEventBus'

function PageLoader() {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    pageLoaderEventBus.addListener((isLoaded) => {
      setIsVisible(isLoaded)
    })
  }, [])

  return (
    <div className='page-loader' data-visible={isVisible}>
      <img src='colored_loader.gif' alt='Loading...'/>
    </div>
  );
}

export default PageLoader;