import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './NavBar.scss';
import { FormattedMessage } from 'react-intl';
import { paths } from '../../routing/paths';

function NavBar() {
  const [moblieToggleVisible, setMoblieToggleVisible] = useState(false)
  
  function mobileToggleClick() {
    setMoblieToggleVisible(!moblieToggleVisible)
  }

  function navLinkClasses(isActive: boolean): string {
    return isActive ? 'nav-link fw-bold': 'nav-link'
  }

  return (
    <nav className='nav-bar'>
      <div className='nav-bar-logo d-flex align-items-center'>
        <Link to={ paths.home }>
          <img src='w_icon_with_name.svg' alt='Tehiru'/>
        </Link>
      </div>

      <button className='moblie-nav-toggle' aria-controls='nav-bar-links' aria-expanded={moblieToggleVisible}
        onClick={mobileToggleClick}>
          <span className='sr-only'><FormattedMessage id='app.nav-bar.link.menu'/></span>
      </button>

      <div id='nav-bar-links' className='nav-bar-links' data-visible={moblieToggleVisible}>
        <NavLink to={ paths.home } className={({ isActive }) => navLinkClasses(isActive)}>
          <FormattedMessage id='app.nav-bar.link.home'/>
        </NavLink>
        <NavLink to={ paths.team } className={({ isActive }) => navLinkClasses(isActive)}>
          <FormattedMessage id='app.nav-bar.link.about'/>
        </NavLink>
        <NavLink className={({ isActive }) => isActive ? navLinkClasses(isActive): 'btn btn-primary nav-link--upper'} 
          to={ paths.contactUs }>
          <FormattedMessage id='app.nav-bar.link.contactUs'/>
        </NavLink>

      </div>
    </nav>
  )
}

export default NavBar;
