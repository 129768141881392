import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './CapabilitiesSection.scss';
import { isBiggerThanSize } from '../../../terhiru-ui/js/utils';

function CapabilitiesSection() {
  const [isDesktopSize, setIsDesktopSize] = useState(isBiggerThanSize(768));
  useEffect(() => {

    function windowResize() {
      setIsDesktopSize(isBiggerThanSize(768))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])
  
  return (
      <section className='cabapilities-section'>
        <div className='cabapilities-section-container'>
            <h2><FormattedMessage id="app.home.capabilities.header"/></h2>
            <p><FormattedMessage id="app.home.capabilities.desc"/></p>
        </div>
        <div className='cabapilities-section-graph'>
          <div className='cabapilities-section-graph-img-container'>
            <img className='cabapilities-section-graph--img' src={`https://storage.googleapis.com/public-bucket.tehiru.space/images/graph${isDesktopSize ? "" : "SM"}.svg`} alt='Tehiru - Payload capability range'/>
          </div>
          <div className='cabapilities-section-graph-map'>
            <img className='cabapilities-section-graph--read' src={`https://storage.googleapis.com/public-bucket.tehiru.space/images/graphMap${isDesktopSize ? "" : "SM"}.svg`} alt='Tehiru - Payload capability range'/>
          </div>
        </div>
      </section>
  );
}

export default CapabilitiesSection;

