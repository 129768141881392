import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.scss';
import { paths } from './routing/paths';
import HomePage from './components/home/HomePage'
import NotFoundPage from './components/notfound/NotFoundPage'
import LayoutOutlet from './components/layout/LayoutOutlet';

import "swiper/css/bundle";
import TeamPage from './components/teamPage/TeamPage';
import ContactUsPage from './components/contactUsPage/ContactUsPage';
import InvestPage from './components/investPage/InvestPage';

function App() {
  return (
    <BrowserRouter>
          <Routes>
            <Route path='' element={<LayoutOutlet/>} >
              <Route path={paths.home} element={<HomePage />} />
              <Route path={paths.team} element={<TeamPage />} />
              <Route path={paths.contactUs} element={<ContactUsPage />} />
              <Route path={paths.invest} element={<InvestPage />} />
            </Route>
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
      </BrowserRouter>
  );
}

export default App;
