import React from 'react';
import './Footer.scss';

function Footer() {

  return (
      <footer className='footer'>
        <div className='footer-content'>
          <span>+1(646) 470-7263</span>
          <span><a className='footer-mailto' href="mailto:inquiries@tehiru.space">inquiries@tehiru.space</a></span>
          <span className='footer-icons'>
            <a className='d-flex justify-content-center align-items-center' href='https://www.linkedin.com/company/tehiru'>
              <img className='footer-icon' src='/assets/linkedin.svg' alt='Tehiru linkedin'/>
            </a>
          </span>
          <div>© {new Date().getFullYear()} Tehiru</div>
        </div>
      </footer>
  );
}
/*
            <a className='d-flex justify-content-center align-items-center' href='https://www.instagram.com/tehiruspace/'>
              <img className='footer-icon' src='/assets/insta.svg' alt='Tehiru instagram'/>
            </a>
*/
export default Footer;
