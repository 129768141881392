import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Footer from '../footer/Footer';
import pageLoaderEventBus from '../../core/pageLoaderEventBus'
import './ContactUsPage.scss';
import { makeContactUsInfo } from '../../api/providers/mailProvider';
import apiProvider from '../../api/apiProvider';
import { Helmet } from 'react-helmet';
import { canonialUrls } from '../../routing/paths';

enum MailMessageStatus {
  NONE,
  SENT,
  ERROR
}

function ContactUsPage() {
  const [messageStatus, setMessageStatus] = useState(MailMessageStatus.NONE)
  const [formInputValues, setFormInputValues] = useState(makeContactUsInfo())

  async function handleFormSubmit(event: React.FormEvent) {
    event.preventDefault()

    pageLoaderEventBus.load()

    const mailProvider = apiProvider.mailProvider
    const messageSent = await mailProvider.contactUs(formInputValues)
    let newMessageStatus = messageStatus
    messageSent ?
      newMessageStatus = MailMessageStatus.SENT :
      newMessageStatus = MailMessageStatus.ERROR

    setMessageStatus(newMessageStatus)
    pageLoaderEventBus.unload()

  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    const { name, value } = event.target
    setFormInputValues((formInputs) => ({
      ...formInputs,
      [name]: value
    }))
  }

  function buildMessageStatus() {
    let innerContect: JSX.Element
    switch (messageStatus) {
      case MailMessageStatus.SENT:
        innerContect = (
          <>
            <h2><FormattedMessage id='app.contact-us-page.message-sent.title'/></h2>
            <p>
              <FormattedMessage id='app.contact-us-page.message-sent.desc-1'/>
              <br/>
              <FormattedMessage id='app.contact-us-page.message-sent.desc-2'/>
            </p>
          </>
        )
        break
      case MailMessageStatus.ERROR:
        innerContect = (
          <>
            <h2><FormattedMessage id='app.contact-us-page.message-sent-error.title'/></h2>
            <p>
              <FormattedMessage id='app.contact-us-page.message-sent-error.desc'/>
            </p>
          </>
        )
        break
      default:
        innerContect = (
          <>
            <h2>Error</h2>
          </>
        )

    }
    return (
      <div className='d-flex flex-column'>
          { innerContect }
      </div>
    )
  }

  return (
    <> 
      <Helmet>
        <title>Contact us - Tehiru</title>
        <meta name="description" content="Need to send your payload to space, or want to learn more? Fill out the form below and someone from our team will get back to you."></meta>
        <link rel="canonical" href={canonialUrls.contactUs} />
      </Helmet>
      <section className='contact-us-page-contianer p-b-6 p-t-7'>
        { messageStatus !== MailMessageStatus.NONE ? 
            buildMessageStatus()
          :
          <div className='contact-us-grid'>
            <div>
              <h2><FormattedMessage id='app.contact-us-page.title'/></h2>
              <p><FormattedMessage id='app.contact-us-page.desc'/></p>
            </div>
            <form 
              className='contact-us-page-form'
              onSubmit={handleFormSubmit}>
              <div className='form-row'>
                <input 
                  placeholder='First Name*' 
                  type='text' 
                  className='form-control'
                  name='firstName'
                  data-not-empty={formInputValues.firstName !== ''}
                  value={formInputValues.firstName}
                  onChange={handleInputChange} 
                  required/>
                <input 
                  placeholder='Surname*' 
                  type='text' 
                  className='form-control'
                  name='surname'
                  data-not-empty={formInputValues.surname !== ''}
                  value={formInputValues.surname}
                  onChange={handleInputChange} 
                  required/>
              </div>
              <input 
                placeholder='Email Address*' 
                type='email' 
                className='form-control'
                data-not-empty={formInputValues.email !== ''}
                name='email'
                value={formInputValues.email}
                onChange={handleInputChange} 
                required/>
              <div className='form-row'>
                <input 
                  placeholder='Company' 
                  type='text' 
                  className='form-control'
                  data-not-empty={formInputValues.company !== ''}
                  name='company'
                  value={formInputValues.company}
                  onChange={handleInputChange} 
                  />
                <input 
                  placeholder='Country' 
                  type='text' 
                  className='form-control'
                  data-not-empty={formInputValues.country !== ''}
                  name='country'
                  value={formInputValues.country}
                  onChange={handleInputChange} 
                  />
              </div>
              <textarea 
                placeholder='How can we help?' 
                className='form-control'
                data-not-empty={formInputValues.freeText !== ''}
                name='freeText'
                value={formInputValues.freeText}
                onChange={handleInputChange} 
                />
              <div className='d-flex justify-content-end'>
                <button className='btn btn-primary p-x-5'>Send</button>
              </div>
            </form>
          </div> 
      }
      </section>
      <Footer/>
    </>
  );
}

export default ContactUsPage;
