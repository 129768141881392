import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from '../../routing/paths';
import { scrollToTop } from '../../utils/scroll-utils';
import './ContactUsSection.scss';

function ContactUsSection() {
  return (
      <section className='contact-us-section'>
        <div className='contact-us-section-inside-container'>
          <span><FormattedMessage id="app.contact-us-section.p"/></span>
          <h2><FormattedMessage id="app.contact-us-section.header"/></h2>
          <Link onClick={scrollToTop} to={paths.contactUs} className='btn btn-primary'>
            <FormattedMessage id="app.contact-us-section.button"/>
          </Link>
        </div>
      </section>
  );
}

export default ContactUsSection;
