import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { screenSizes } from '../../../terhiru-ui/js/consts';
import { isBiggerThanWindowSize } from '../../../terhiru-ui/js/utils';
import './TeamSubDesc.scss';

function TeamSubDesc() {
    const [isMidSize, setIsMidSize] = useState(isBiggerThanWindowSize(screenSizes.md));

    useEffect(() => {

      function windowResize() {
        setIsMidSize(isBiggerThanWindowSize(screenSizes.md))
      }
  
      window.addEventListener('resize', windowResize)
      return () => window.removeEventListener('resize', windowResize)
    }, [])
    return (
      isMidSize ?
      <section className='team-sub-desc-section'>
          <div>
            <FormattedMessage id='app.team.team-sub-desc-section-par-1'/>
            <br />
            <FormattedMessage id='app.team.team-sub-desc-section-par-2'/>
            <br />
            <FormattedMessage id='app.team.team-sub-desc-section-par-3'/>
            <br />
            <FormattedMessage id='app.team.team-sub-desc-section-par-4'/>
            <br />
            <FormattedMessage id='app.team.team-sub-desc-section-par-5'/>
            <br />
            <FormattedMessage id='app.team.team-sub-desc-section-par-6'/>
            <br />
          </div>
          <div>
            <p>
              <FormattedMessage id='app.team.team-sub-desc-section-par-7'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-par-8'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-par-9'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-par-10'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-par-11'/>
              <br />
              <br />
              <span className='fw-bold m-0'>
              <FormattedMessage id='app.team.team-sub-desc-section-par-12'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-par-13'/>
            </span>
            </p>
          </div>
      </section>
      :
      <section className='team-sub-desc-section'>
          <div>
            <FormattedMessage id='app.team.team-sub-desc-section-sm-p1'/>
          </div>
          <div>
            <p>
              <FormattedMessage id='app.team.team-sub-desc-section-sm-p2-1'/>
              <br />
              <br />
              <span className='fw-bold m-0'>
              <FormattedMessage id='app.team.team-sub-desc-section-sm-p2-2'/>
              <br />
              <FormattedMessage id='app.team.team-sub-desc-section-sm-p2-3'/>
            </span>
            </p>
          </div>
      </section>
    );
  }
  
  export default TeamSubDesc;
  
