import React from 'react';
import { FormattedMessage } from 'react-intl';
import Footer from '../footer/Footer';
import './TeamPage.scss';
import TeamDesc from './team-desc/TeamDesc';
import TeamSubDesc from './teamSubDesc/TeamSubDesc';
import TeamSection from './teamSection/TeamSection';
import ContactUsSection from '../contactUsSection/ContactUsSection';

import { Helmet } from "react-helmet";
import { canonialUrls } from '../../routing/paths';

function TeamPage() {
  return (
    <>
      <Helmet>
        <title>Meet our team - Tehiru</title>
        <meta name="description" content="Founded in 2018, Tehiru is a groundbreaking small payload startup launch company that's spearheading a new era in the space industry."></meta>
        <link rel="canonical" href={canonialUrls.team} />
      </Helmet>
      <section className='our-team-section'>
        <div className='our-team-section-text container'>
          <h1 className='m-b-0'><FormattedMessage id='app.team.out-team-section.title'/></h1>
          <span><FormattedMessage id='app.team.out-team-section.desc'/></span>
        </div>
        </section>

      <TeamSubDesc/>
      <TeamDesc/>
      <TeamSection/>
      <ContactUsSection/>
      <Footer/>
    </>
  );
}

export default TeamPage;
