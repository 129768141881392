import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { paths } from '../../../routing/paths';
import { scrollToTop } from '../../../utils/scroll-utils';
import './MeetTheTeamSection.scss';

function MeetTheTeamSection() {
  return (
      <section className='meet-the-team-section'>
        <div className='meet-the-team-section-opacity-container'>
          <div>
            <p><FormattedMessage id="app.home.meet-team-section.desc"/></p>
            <Link onClick={scrollToTop} to={paths.team} className='btn btn-secondary'>Meet the team</Link>
          </div>
        </div>
      </section>
  );
}

export default MeetTheTeamSection;
