import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isBiggerThanWindowSize } from '../../../terhiru-ui/js/utils';
import { screenSizes } from '../../../terhiru-ui/js/consts';
import './TechSection.scss';

function ReadMore() {
  return <>
    Read more
    <svg className='m-s-1' width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.49609 1L5.24805 5.29995L1 1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg> 
  </>
}

function ReadLess() {
  return <>
    Read less
    <svg className='m-s-1' width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 6L5.24805 1.70005L9.49609 6" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>  
  </>
}

type techSectionItem = {
  imageUrl: string,
  imageAlt: string,
  imageUrlSM: string,
  headerIntlId: string,
  descIntlId: string,
  subDescIntlId: string,
  subDescIntlId1:string,
}


function TechSection() {
  const [isDesktopSize, setIsDesktopSize] = useState(isBiggerThanWindowSize(screenSizes.md));
  const itemsMap: techSectionItem[] = [
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/rocket_blue_sky.png',
      imageUrlSM: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/rocket_blue_sky_sm.jpg',
      imageAlt: 'rocket launch',
      headerIntlId: 'app.home.tech-section.orbital-lunch.header',
      descIntlId: 'app.home.tech-section.orbital-lunch.desc',
      subDescIntlId:'app.home.tech-section.orbital-lunch.subDesc',
      subDescIntlId1:'app.home.tech-section.orbital-lunch.subDesc1',

    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/launchAndReturnImg.png',
      imageUrlSM: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/launchAndReturnImgSM.jpg',
      imageAlt: 'rocket  open parachute',
      headerIntlId: 'app.home.tech-section.lunch-and-return.header',
      descIntlId: 'app.home.tech-section.lunch-and-return.desc',
      subDescIntlId:'app.home.tech-section.lunch-and-return.subDesc',
      subDescIntlId1:'app.home.tech-section.lunch-and-return.subDesc1',      
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/resupplyMissionImg.png',
      imageUrlSM: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/resupplyMissionImgSM.jpg',
      imageAlt: 'space station',
      headerIntlId: 'app.home.tech-section.resupply.header',
      descIntlId: 'app.home.tech-section.resupply.desc',
      subDescIntlId:'app.home.tech-section.resupply.subDesc',
      subDescIntlId1:'app.home.tech-section.resupply.subDesc1',
    },
    {
      imageUrl: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/tech_globes.png',
      imageUrlSM: 'https://storage.googleapis.com/public-bucket.tehiru.space/images/tech_globes_sm.jpg',
      imageAlt: 'earth tech',
      headerIntlId: 'app.home.tech-section.constellations.header',
      descIntlId: 'app.home.tech-section.constellations.desc',
      subDescIntlId: 'app.home.tech-section.constellations.subDesc',
      subDescIntlId1:'app.home.tech-section.constellations.subDesc1',
    },
  ]

  const [showMoreArr, setShowMoreArr] = useState([false, false, false, false])

  useEffect(() => {

    function windowResize() {
      setIsDesktopSize(isBiggerThanWindowSize(screenSizes.md))
    }

    window.addEventListener('resize', windowResize)
    return () => window.removeEventListener('resize', windowResize)
  }, [])

  function renderForDestktop(): JSX.Element[] {
    return itemsMap.map((item, index) => {
      return (
        <div key={index} className='tech-section-grid'>
          <div className='w-75'>
            <h3 className='m-b-0'><FormattedMessage id={item.headerIntlId}/></h3>
            <h3 className='tech-section-sub-title'><FormattedMessage id={item.descIntlId}/></h3>
            <section className='little-format'>
              <FormattedMessage id={item.subDescIntlId}/>
              <span className='tech-section-readmore' data-visible={showMoreArr[index]}>
                <br />
                <FormattedMessage id={item.subDescIntlId1}/>
              </span>
            </section>
            <div className='tech-read-more'>
              <p className='tech-read-more-button' onClick={() => {
                const newShowMoreArr = showMoreArr.map((showMore, currIndex) => currIndex === index ? !showMore : showMore)
                setShowMoreArr(newShowMoreArr)
              }}>
                {
                  showMoreArr[index] ? ReadLess(): ReadMore()
                }
              </p>
            </div>

          </div>
          <div className='position-relative w-100 h-100'>
            <img src={item.imageUrl} alt={item.imageAlt} className='bg-img'/>
          </div>
        </div>
      )
    })
  }

  function renderForPhone(): JSX.Element[] {
    
    return itemsMap.map((item, index) => {
      return (
        <div key={index} className='tech-section-phone'>
          <div className='tech-section-phone-content'>
            <h3 className='m-b-0'><FormattedMessage id={item.headerIntlId}/></h3>
            <h3 className='tech-section-sub-title'><FormattedMessage id={item.descIntlId}/></h3>
            <FormattedMessage id={item.subDescIntlId}/>
            <span className='tech-section-readmore' data-visible={showMoreArr[index]}>
              <br/>
              <FormattedMessage id={item.subDescIntlId1}/>
            </span>
            <div className='tech-read-more'>
              <p className='tech-read-more-button' onClick={(e) => {
                const newShowMoreArr = showMoreArr.map((showMore, currIndex) => currIndex === index ? !showMore : showMore)
                setShowMoreArr(newShowMoreArr)
              }}>
                {
                  showMoreArr[index] ? ReadLess(): ReadMore()
                }
              </p>
            </div>
            <span className='tech-section-phone-img-gradiant'></span>
          </div>

          <img src={item.imageUrlSM} alt={item.imageAlt} className='bg-img n-z-index-2'/>
        </div>
      )
    })
  }

  return (
      <section className='tech-section'>
        {isDesktopSize ? 
          renderForDestktop():
          renderForPhone()
        }
      </section>
  );
}
/*
        <div className='tech-section-title'>
          <h2><FormattedMessage id="app.home.tech-section.header"/></h2>
        </div>
*/
export default TechSection;
